import { db } from '../firebase';
import { doc, getDoc, collection, getDocs, setDoc } from 'firebase/firestore';

import { useEffect, useState, useRef } from 'react';

import BrainstormingCanvas from './BrainstormingCanvas';
import Header from './Header';

import { Lightbulb } from 'lucide-react';

function Dashboard({ handleSignOut, signedInUserTeam, signedInUser }) {
  const [userData, setUserData] = useState({});
  const [sessions, setSessions] = useState([]);
  const [showTree, setShowTree] = useState(false);
  const [selectedSession, setSelectedSession] = useState('');

  const sessionRef = useRef();

  useEffect(() => {
    const value1 = localStorage.getItem('UNIQIUM_SHOW_TREE');
    setShowTree(JSON.parse(value1));
    const value2 = localStorage.getItem('UNIQIUM_SELECTED_SESSION');
    setSelectedSession(JSON.parse(value2));
  }, []);

  useEffect(() => {
    localStorage.setItem('UNIQIUM_SHOW_TREE', JSON.stringify(showTree));
    localStorage.setItem('UNIQIUM_SELECTED_SESSION', JSON.stringify(selectedSession));
    const getUserData = async () => {
      const UID = signedInUser.uid;
      const docRef = doc(db, 'teams', signedInUserTeam, 'users', UID);
      const docSnap = await getDoc(docRef);
      setUserData(docSnap.data());
    }
    getUserData();
    const getSessions = async () => {
      const UID = signedInUser.uid;
      const colRef = collection(db, 'teams', signedInUserTeam, 'sessions');
      const colSnap = await getDocs(colRef);
      const tmp = [];
      colSnap.forEach((doc) => {
        doc.data().assignees.forEach((assigneeId) => {
          if (assigneeId === UID) {
            const sessionId = doc.id;
            const sessionName = doc.data().sessionName;
            const project = doc.data().project;
            const objective = doc.data().objective;
            const instructions = doc.data().instructions;
            const assignees = doc.data().assignees;
            const session = {
              sessionId: sessionId,
              sessionName: sessionName,
              project: project,
              objective: objective,
              instructions: instructions,
              assignees: assignees
            }
            tmp.push(session);
          }
        });
      });
      setSessions(tmp);
    }
    getSessions();
  }, [signedInUser, signedInUserTeam, showTree, selectedSession]);

  const handleStartSession = async (sessionId) => {
    setSelectedSession(sessionId);
    const docRef = doc(db, 'teams', signedInUserTeam, 'sessions', sessionId, 'trees', signedInUser.uid);
    getDoc(docRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          // Do nothing
        } else {
          setDoc(docRef, { name: 'Set a \'Seed Idea\' to begin...', children: [], attributes: { isFavorite: false } });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setShowTree(true);
  }

  return (
    <div className='screen'>
      <Header userData={userData} handleSignOut={handleSignOut} showTree={showTree} setShowTree={setShowTree} />
      <div className={!showTree ? 'dash-container' : 'dash-container canvas'}>
        {!showTree && <h1 className='page-title'>Brainstorming Sessions</h1>}
        {!showTree ?
          <ul className='sessions-container'>
            {sessions.map((session) => {
              return (<li
                ref={sessionRef}
                key={session.sessionId}
                sessionid={session.sessionId}
              >
                <div className='session-card'>
                  <p className='session-card-title'>{session.sessionName}</p>
                  <p className='session-card-project'>{session.project}</p>
                </div>
                <button
                  onClick={() => handleStartSession(session.sessionId)}
                  className='btn-regular btn-icon'
                >
                  <Lightbulb size={16} />
                  Brainstorm
                </button>
              </li>);
            })}
          </ul>
          :
          <>
            <BrainstormingCanvas
              selectedSession={selectedSession}
              signedInUserTeam={signedInUserTeam}
              signedInUser={signedInUser}
            />
          </>
        }
      </div>
    </div>
  );
}

export default Dashboard;