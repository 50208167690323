import { CheckCircle2, Gift } from 'lucide-react';
import React from 'react';

function Payment() {
  return (
    <div className='payment-container'>
      <p className='trial-badge'><Gift size={20} /> 7 days free trial</p>
      <div style={{ width: 400, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <h1>Pick the right plan for your creative business</h1>
        <p style={{ marginTop: 8, marginBottom: 8 }}>Say goodbye to conventional brainstorming meetings. Save time, energy, and money.</p>
      </div>
      <div style={{ display: 'flex', gap: 16 }}>
        <div className='plan-card'>
          <p className='price'>$99<span style={{ color: '#1F3642', fontSize: 16, }}> / month</span></p>
          <div>
            <label>Studio</label>
            <p style={{ marginTop: 8 }}>Enable your small team to get more done in less time</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
            <p className='feature'><CheckCircle2 size={16} color='#5DEB74' />Up to 10 users</p>
            <p className='feature'><CheckCircle2 size={16} color='#5DEB74' />AI brainstorming</p>
            <p className='feature'><CheckCircle2 size={16} color='#5DEB74' />Collaboration</p>
            <p className='feature'><CheckCircle2 size={16} color='#5DEB74' />Productivity</p>
            <p className='feature'><CheckCircle2 size={16} color='#1F3642' />Access to beta features</p>
            <p className='feature'><CheckCircle2 size={16} color='#1F3642' />Custom features</p>
          </div>
          <a href='mailto:contact@uniqium.com' style={{ all: 'unset' }}><button className='btn-regular'>Contact us</button></a>
        </div>

        <div className='plan-card'>
          <p className='price'>$199<span style={{ color: '#1F3642', fontSize: 16, }}> / month</span></p>
          <div>
            <label>Agency</label>
            <p style={{ marginTop: 8 }}>Make your existing workflows ten times more effecient</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
            <p className='feature'><CheckCircle2 size={16} color='#5DEB74' />Up to 30 users</p>
            <p className='feature'><CheckCircle2 size={16} color='#5DEB74' />AI brainstorming</p>
            <p className='feature'><CheckCircle2 size={16} color='#5DEB74' />Collaboration</p>
            <p className='feature'><CheckCircle2 size={16} color='#5DEB74' />Productivity</p>
            <p className='feature'><CheckCircle2 size={16} color='#5DEB74' />Access to beta features</p>
            <p className='feature'><CheckCircle2 size={16} color='#1F3642' />Custom features</p>
          </div>
          <a href='mailto:contact@uniqium.com' style={{ all: 'unset' }}><button className='btn-regular'>Contact us</button></a>
        </div>

        <div className='plan-card'>
          <p className='price'>$499<span style={{ color: '#1F3642', fontSize: 16, }}> / month</span></p>
          <div>
            <label>Enterprise</label>
            <p style={{ marginTop: 8 }}>Reclaim tens of thousands of dollars in lost productivity</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
            <p className='feature'><CheckCircle2 size={16} color='#5DEB74' />Unlimited users</p>
            <p className='feature'><CheckCircle2 size={16} color='#5DEB74' />AI brainstorming</p>
            <p className='feature'><CheckCircle2 size={16} color='#5DEB74' />Collaboration</p>
            <p className='feature'><CheckCircle2 size={16} color='#5DEB74' />Productivity</p>
            <p className='feature'><CheckCircle2 size={16} color='#5DEB74' />Access to beta features</p>
            <p className='feature'><CheckCircle2 size={16} color='#5DEB74' />Custom features</p>
          </div>
          <a href='mailto:contact@uniqium.com' style={{ all: 'unset' }}><button className='btn-regular'>Contact us</button></a>
        </div>
      </div>
    </div>
  );
}

export default Payment;