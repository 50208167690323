import React, { useRef, useEffect } from "react";

const DynamicTextArea = ({ nodeToEditText, nodeToEditRef, handleUpdateButtonClick, nodeClicked, nodeClickedChildren, getNewNodeText }) => {
  const mirrorRef = useRef(null);

  useEffect(() => {
    mirrorRef.current.textContent = nodeToEditText;
    nodeToEditRef.current.style.height = mirrorRef.current.offsetHeight + "px";
  }, [nodeToEditText, nodeToEditRef]);

  return (
    <div className='textarea-container'>
      <pre ref={mirrorRef} className='textarea-mirror'></pre>
      <textarea
        value={nodeToEditText}
        onChange={event => getNewNodeText(event)}
        placeholder='Write your idea here...'
        onKeyDown={(event) => { handleUpdateButtonClick(event, nodeClicked, nodeClickedChildren) }}
        ref={nodeToEditRef}
        maxLength={200}
        autoFocus
      />
    </div>
  );
};

export default DynamicTextArea;