import { useRef, useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';
import { User } from 'lucide-react';

function AddSessionForm({ sessions, setSessions, setShowAddSessionForm, signedInUserTeam, teamMembers, signedInUser }) {
  const [assignees, setAssignees] = useState([]);

  const formRef = useRef();

  useEffect(() => {
    const tmp = [...teamMembers];
    tmp.forEach((user) => {
      user.isAssigned = false;
    });
    setAssignees(tmp);
  }, [teamMembers]);

  const handleAddSession = (e) => {
    e.preventDefault();
    const generateRandomId = () => Math.random().toString(36).substring(2, 7);

    const sessionId = generateRandomId();
    const sessionName = e.target.sessionName.value;
    const project = e.target.project.value;
    const objective = e.target.objective.value;
    const instructions = e.target.instructions.value;
    const assigneeUIDs = [signedInUser.uid];
    const favorites = [];
    const timerValue = {};
    assignees.forEach((user) => {
      if (user.isAssigned) {
        assigneeUIDs.push(user.UID);
      }
    });
    timerValue[signedInUser.uid] = 300;
    assignees.forEach((user) => {
      if (user.isAssigned) {
        const userId = user.UID;
        timerValue[userId] = 300;
      }
    });
    const newSession = {
      sessionId: sessionId,
      sessionName: sessionName,
      project: project,
      objective: objective,
      instructions: instructions,
      assignees: assigneeUIDs,
      favorites: favorites,
      timerValue: timerValue,
    };
    const docRef = doc(db, 'teams', signedInUserTeam, 'sessions', sessionId);
    setDoc(docRef, {
      sessionName: sessionName,
      project: project,
      objective: objective,
      instructions: instructions,
      assignees: assigneeUIDs,
      favorites: favorites,
      timerValue: timerValue,
    });
    const tmp = [...sessions];
    tmp.push(newSession);
    setSessions(tmp);
    setShowAddSessionForm(false);
    formRef.current.reset();
  }

  const handleSetAssignee = (assigneeUID) => {
    const tmp = [...assignees];
    tmp.forEach((user) => {
      if (user.UID === assigneeUID) {
        user.isAssigned = !user.isAssigned;
      }
    });
    setAssignees(tmp);
  }

  return (
    <form
      className='add-session-container'
      ref={formRef}
      onSubmit={(e) => handleAddSession(e)}
    >
      <div className='add-session-form'>
        <h2>New Session</h2>
        <div className='input-container'>
          <label>Session Name</label>
          <input
            name='sessionName'
            placeholder='What are you working on?'
            autoComplete='off'
          />
        </div>
        <div className='input-container'>
          <label>Project</label>
          <input
            name='project'
            placeholder='Acme Inc'
            autoComplete='off'
          />
        </div>
        <div className='input-container'>
          <label>Objective</label>
          <input
            name='objective'
            placeholder='What are you aiming to achieve?'
            autoComplete='off'
          />
        </div>
        <div className='input-container'>
          <label>Instructions</label>
          <textarea
            name='instructions'
            placeholder='Specify any special instruction your team needs to follow'
            autoComplete='off'
          />
        </div>
        <button className='btn-cta' type='submit'>Create Session</button>
      </div>
      <div className='add-session-assignees'>
        <label>Assignees</label>
        <ul className='assignees-container'>
          {assignees.map((user) => {
            return (
              <li
                className={user.isAssigned ? 'assignee-container assigned' : 'assignee-container'}
                key={user.UID}
                uid={user.UID}
                onClick={() => handleSetAssignee(user.UID)}
              >
                <div className={user.isAssigned ? 'avatar avt-assigned' : 'avatar'}><User size={32} /></div>
                {user.name}
              </li>
            );
          })}
        </ul>
      </div>
    </form>
  );
}

export default AddSessionForm;