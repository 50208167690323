import logo from '../assets/logo-color.png';

import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useRef } from "react";

function SignInForm() {
	const formRef = useRef();

	const handleSignIn = (e) => {
		e.preventDefault();
		const email = e.target.email.value;
		const password = e.target.password.value;
		formRef.current.reset();
		signInWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				//Signed In
			})
			.catch((err) => {
				console.log(err.message);
			});
	}
	return (
		<div className='sign-in-card'>
			<img src={logo} width={180} alt='Uniqium Logo' />
			<form ref={formRef} onSubmit={(e) => handleSignIn(e)}>
				<div className='input-container'>
					<label>Email</label>
					<input name="email" placeholder="john@acme.com" autoComplete='off' />
				</div>
				<div className='input-container'>
					<label>Password</label>
					<input name="password" type="password" />
				</div>
				<button className='btn-cta' type="submit">Sign In</button>
			</form>
		</div>
	);
}

export default SignInForm;