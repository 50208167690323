import { useEffect, useState } from 'react';
import { useTimer } from 'react-timer-hook';

import { Play, Pause } from 'lucide-react';

function Timer({ setIsTimerOn, timerValue, setTimerValue, autoStart, checkIfRunning, saveTime }) {
  const [runningTime, setRunningTime] = useState(timerValue);
  const [dontReload, setDontReload] = useState(false);

  const expiryTimestamp = new Date();
  // I have no idea why I had to devide by 1. Doesn't work without it.
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + timerValue / 1);

  const handleSessionEnd = () => {
    setIsTimerOn(false);
    saveTime(runningTime);
    setTimerValue(0);
    setTimeout(() => {
      alert('Time\'s up! Select your favorite ideas.');
    }, 500)
  }

  const {
    seconds,
    minutes,
    isRunning,
    pause,
    resume,
  } = useTimer({ expiryTimestamp, autoStart, onExpire: () => handleSessionEnd() });

  const handleStart = () => {
    resume();
    setDontReload(true);
  }

  const handlePause = () => {
    pause();
    setDontReload(false);
    saveTime(runningTime);
  }

  useEffect(() => {
    if (!dontReload) {
      return
    }
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      return (e.returnValue = '');
    }
    window.addEventListener('beforeunload', handleBeforeUnload, { capture: true });
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload, { capture: true });
    }
  }, [dontReload]);

  useEffect(() => {
    const newTime = runningTime - 1;
    setRunningTime(newTime);
  }, [seconds]);

  useEffect(() => {
    checkIfRunning(isRunning);
  }, [isRunning, checkIfRunning]);

  return (
    <div className='control-container'>
      <div className='time'>
        <span>{minutes}</span>:<span>{JSON.stringify(seconds).length === 1 ? `0${seconds}` : seconds}</span>
      </div>
      {!isRunning ? <button className='btn-cta btn-icon btn-timer' onClick={() => handleStart()}><Play />Start</button> : <button className='btn-regular btn-icon btn-timer btn-pause' onClick={() => handlePause()}><Pause />Pause</button>}
    </div>
  );
}

export default Timer;