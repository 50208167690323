import { useEffect, useState, useRef } from 'react';
import { db } from '../firebase';
import { collection, getDocs, getDoc, doc, setDoc } from 'firebase/firestore';

import AddUserForm from './AddUserForm';
import AddSessionForm from './AddSessionForm';
import SessionReviewScreen from './SessionReviewScreen';
import Header from './Header';
import BrainstormingCanvas from './BrainstormingCanvas';
import Payment from './Payment';

import { ClipboardCheck, Plus, User, X, Lightbulb } from 'lucide-react';

function AdminDashboard({ handleSignOut, signedInUserTeam, signedInUser }) {
	const [userData, setUserData] = useState({});
	const [teamMembers, setTeamMembers] = useState([]);
	const [sessions, setSessions] = useState([]);
	const [selectedSession, setSelectedSession] = useState('');
	const [showSession, setShowSession] = useState(false);
	const [showAddUserForm, setShowAddUserForm] = useState(false);
	const [showAddSessionForm, setShowAddSessionForm] = useState(false);
	const [showTree, setShowTree] = useState(false);
	const [isActive, setIsActive] = useState(undefined);

	const sessionListRef = useRef();

	const handleReviewSession = (sessionId) => {
		setSelectedSession(sessionId);
		setShowSession(true);
	}

	const handleStartSession = async (sessionId) => {
		setSelectedSession(sessionId);
		const docRef = doc(db, 'teams', signedInUserTeam, 'sessions', sessionId, 'trees', signedInUser.uid);
		getDoc(docRef)
			.then((docSnap) => {
				if (docSnap.exists()) {
					// Do nothing
				} else {
					setDoc(docRef, { name: 'Set a \'Seed Idea\' to begin...', children: [], attributes: { isFavorite: false } });
				}
			})
			.catch((err) => {
				console.log(err);
			});
		setShowTree(true);
	}

	useEffect(() => {
		const value1 = localStorage.getItem('UNIQIUM_SHOW_TREE');
		setShowTree(JSON.parse(value1));
		const value2 = localStorage.getItem('UNIQIUM_SELECTED_SESSION');
		setSelectedSession(JSON.parse(value2));
	}, []);

	useEffect(() => {
		localStorage.setItem('UNIQIUM_SHOW_TREE', JSON.stringify(showTree));
		localStorage.setItem('UNIQIUM_SELECTED_SESSION', JSON.stringify(selectedSession));
		const getUserData = async () => {
			const UID = signedInUser.uid;
			const docRef = doc(db, 'teams', signedInUserTeam, 'users', UID);
			const docSnap = await getDoc(docRef);
			setUserData(docSnap.data());
		}
		getUserData();
		const getTeamMembers = async () => {
			const colRef = collection(db, 'teams', signedInUserTeam, 'users');
			const colSnap = await getDocs(colRef);
			const tmp = [];
			colSnap.forEach((doc) => {
				if (doc.data().isAdmin === false) {
					const UID = doc.id;
					const name = doc.data().name;
					const email = doc.data().email;
					const user = { UID: UID, name: name, email: email };
					tmp.push(user);
				}
			});
			setTeamMembers(tmp);
		}
		getTeamMembers();
		const getSessions = async () => {
			const colRef = collection(db, 'teams', signedInUserTeam, 'sessions');
			const colSnap = await getDocs(colRef);
			const tmp = [];
			colSnap.forEach((doc) => {
				const sessionId = doc.id;
				const sessionName = doc.data().sessionName;
				const project = doc.data().project;
				const objective = doc.data().objective;
				const instructions = doc.data().instructions;
				const assignees = doc.data().assignees;
				const session = {
					sessionId: sessionId,
					sessionName: sessionName,
					project: project,
					objective: objective,
					instructions: instructions,
					assignees: assignees
				}
				tmp.push(session);
			});
			setSessions(tmp);
		}
		getSessions();
		const getSubStatus = async () => {
			const docRef = doc(db, 'teams', signedInUserTeam);
			const docSnap = await getDoc(docRef);
			const data = docSnap.data();
			setIsActive(data.isActive);
		}
		getSubStatus();
	}, [signedInUserTeam, signedInUser, showTree, selectedSession]);

	return (
		<>
			{isActive === undefined ? <></> : !isActive ? <Payment /> : <div className='screen'>
				<Header userData={userData} handleSignOut={handleSignOut} setShowSession={setShowSession} showSession={showSession} showTree={showTree} setShowTree={setShowTree} />
				<>
					{
						showTree && <>
							<BrainstormingCanvas
								selectedSession={selectedSession}
								signedInUserTeam={signedInUserTeam}
								signedInUser={signedInUser}
							/>
						</>
					}
					{!showSession && !showTree ?
						<div className='admin-dash-container'>
							<div className='admin-dash-left'>
								<div className='page-header-container'>
									<h1 className='page-title'>Seesions</h1>
									<button
										className='btn-regular btn-icon'
										onClick={() => setShowAddSessionForm(!showAddSessionForm)}
									>
										{!showAddSessionForm ? <><Plus size={16} />New Session</> : <><X size={16} />Cancel</>}
									</button>
								</div>
								{!showAddSessionForm &&
									<ul className='sessions-container'>
										{sessions.map((session) => {
											return (<li
												key={session.sessionId}
												sessionid={session.sessionId}
											>
												<div className='session-card'>
													<p className='session-card-title'>{session.sessionName}</p>
													<p className='session-card-project'>{session.project}</p>
												</div>
												<div style={{ display: 'flex', gap: 8 }}>
													<button
														onClick={() => handleStartSession(session.sessionId)}
														className='btn-regular btn-icon'
													>
														<Lightbulb size={16} />
														Brainstorm
													</button>
													<button
														onClick={() => handleReviewSession(session.sessionId)}
														className='btn-regular btn-icon'
													>
														<ClipboardCheck size={16} />
														Review
													</button>
												</div>
											</li>);
										})}
									</ul>
								}
								{showAddSessionForm &&
									<AddSessionForm
										sessions={sessions}
										setSessions={setSessions}
										sessionListRef={sessionListRef}
										setShowAddSessionForm={setShowAddSessionForm}
										signedInUserTeam={signedInUserTeam}
										teamMembers={teamMembers}
										signedInUser={signedInUser}
									/>}
							</div>
							<div className='admin-dash-right'>
								<div className='page-header-container'>
									<h1 className='page-title'>Team</h1>
									<button
										className='btn-regular btn-icon'
										onClick={() => setShowAddUserForm(!showAddUserForm)}
									>
										{!showAddUserForm ? <><Plus size={16} />Add User</> : <><X size={16} />Cancel</>}
									</button>
								</div>
								{!showAddUserForm &&
									<ul ref={sessionListRef}>
										{teamMembers.map((user) => {
											return (
												<li
													className='member-container'
													key={user.UID}
												>
													<div className='avatar avt-small avt-member'><User size={24} /></div>
													<div className='member-details-container'>
														<p className='member-name'>{user.name}</p>
														<p className='member-email'>{user.email}</p>
													</div>
												</li>
											);
										})}
									</ul>
								}
								{showAddUserForm &&
									<AddUserForm
										signedInUserTeam={signedInUserTeam}
										setShowAddUserForm={setShowAddUserForm}
										teamMembers={teamMembers}
										setTeamMembers={setTeamMembers}
									/>}
							</div>
						</div>
						: !showTree &&
						<SessionReviewScreen
							selectedSession={selectedSession}
							signedInUserTeam={signedInUserTeam}
							sigedInUser={signedInUser}
						/>
					}
				</>

			</div>
			}
		</>
	);
}

export default AdminDashboard;