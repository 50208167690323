import { auth } from './firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { db } from './firebase';
import { doc, getDoc } from 'firebase/firestore';

import { useState, useEffect } from 'react';

import SignUpForm from './components/SignUpForm';
import SignInForm from './components/SignInForm';
import AdminDashboard from './components/AdminDashboard';
import Dashboard from './components/Dashboard';

import { ArrowLeft } from 'lucide-react';
import LoadingIndicator from './components/LoadingIndicator';

function App() {
  const [signedInUser, setSignedInUser] = useState(undefined);
  const [signedInUserTeam, setSignedInUserTeam] = useState();
  const [showSignUpForm, setShowSignUpForm] = useState(false);
  const [isAdmin, setIsAdmin] = useState(undefined);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setSignedInUser(user);
        const UID = user.uid;
        const userInfo = JSON.parse(user.displayName);
        const team = userInfo.team;
        setSignedInUserTeam(team);
        const docRef = doc(db, 'teams', team, 'users', UID);
        getDoc(docRef)
          .then((docSnap) => {
            const data = docSnap.data();
            setIsAdmin(data.isAdmin);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setSignedInUser(null);
      }
    });
  }, []);

  const handleSignOut = () => {
    signOut(auth);
    setSignedInUser(null);
    setIsAdmin(undefined);
  }

  return (
    <div>
      {signedInUser === undefined ?
        <></>
        : signedInUser === null ?
          <div className='sign-in-card-container'>
            {showSignUpForm ? <SignUpForm /> : <SignInForm />}
            <div className='sign-in-card-bottom'>
              {!showSignUpForm && <label>Don't have an account yet?</label>}
              <button
                className={!showSignUpForm ? 'btn-regular' : 'btn-regular btn-icon'}
                onClick={() => setShowSignUpForm(!showSignUpForm)}
              >
                {showSignUpForm && <ArrowLeft size={16} />}
                {!showSignUpForm ? 'Sign Up' : 'Back'}
              </button>
            </div>
          </div>
          :
          <div>
            {isAdmin === undefined ?
              <LoadingIndicator />
              : isAdmin ?
                <AdminDashboard
                  signedInUser={signedInUser}
                  signedInUserTeam={signedInUserTeam}
                  handleSignOut={handleSignOut}
                />
                :
                <Dashboard
                  signedInUser={signedInUser}
                  signedInUserTeam={signedInUserTeam}
                  handleSignOut={handleSignOut}
                />
            }
          </div>
      }
    </div>
  );
}

export default App;
