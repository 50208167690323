import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyDMcD3U-IdjyEmZRkKMZCdFfVWl3Hzb4Co",
    authDomain: "uniqium-276f1.firebaseapp.com",
    projectId: "uniqium-276f1",
    storageBucket: "uniqium-276f1.appspot.com",
    messagingSenderId: "495316715466",
    appId: "1:495316715466:web:096aa4ff0c8f76e3d6b133",
    measurementId: "G-TZ3QMXH6G5"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const functions = getFunctions(app);