import React from 'react';
import { Comment } from 'react-loader-spinner';

function LoadingIndicator() {
  return (
    <div className='loading-indicator'>
      Loading
      <Comment
        visible={true}
        height="40"
        width="40"
        ariaLabel="comment-loading"
        wrapperStyle={{ paddingTop: 4 }}
        wrapperClass="comment-wrapper"
        color="#607C8B"
        backgroundColor='transparent'
      />
    </div>
  );
}

export default LoadingIndicator;