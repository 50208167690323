import { useRef, useState } from 'react';
import { functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import { db } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';

import { Comment } from 'react-loader-spinner';

function AddUserForm({ signedInUserTeam, setShowAddUserForm, teamMembers, setTeamMembers }) {
  const [loading, setLoading] = useState(false);

  const formRef = useRef();

  const handleAddUser = (e) => {
    e.preventDefault();
    setLoading(true);
    const email = e.target.email.value;
    const password = e.target.password.value;
    const name = e.target.name.value;
    const team = signedInUserTeam;
    const info = `{"name": "${name}", "team": "${team}"}`;
    const addUser = httpsCallable(functions, 'addUser');
    addUser({ email: email, password: password, info: info })
      .then((res) => {
        const UID = res.data.UID;
        const docRef = doc(db, 'teams', team, 'users', UID);
        setDoc(docRef, { isAdmin: false, name: name, email: email, sessions: [] });
        const user = { UID: UID, name: name, email: email };
        const tmp = [...teamMembers]
        tmp.push(user);
        setTeamMembers(tmp);
        setShowAddUserForm(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <form
      ref={formRef}
      onSubmit={(e) => handleAddUser(e)}
    >
      <div className='input-container'>
        <label>Business Email</label>
        <input autoComplete='off' name='email' placeholder='i.e. john@acme.com' />
      </div>
      <div className='input-container'>
        <label>Full Name</label>
        <input autoComplete='off' name='name' placeholder='John Doe' />
      </div>
      <div className='input-container'>
        <label>Password</label>
        <input autoComplete='off' name='password' type='password' />
      </div>
      <div className='input-container'>
        <label>Confirm Password</label>
        <input autoComplete='off' name='confirm-password' type='password' />
      </div>
      <button className='btn-cta' type='submit'>
        {loading ?
          <>
            <Comment
              visible={true}
              height="40"
              width="40"
              ariaLabel="comment-loading"
              wrapperStyle={{ position: 'absolute' }}
              wrapperClass="comment-wrapper"
              color="#081820"
              backgroundColor='transparent'
            />
            <div style={{ color: 'transparent' }}>Loading</div>
          </>
          :
          'Add User'
        }
      </button>
    </form>
  );
}

export default AddUserForm;