import logo from '../assets/logo-color.png'

import { db } from '../firebase';
import { auth } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, updateProfile, signInWithEmailAndPassword } from 'firebase/auth';
import { useRef } from 'react';

function SignUpForm() {
	const formRef = useRef();

	const handleSignUp = (e) => {
		e.preventDefault();
		const email = e.target.email.value;
		const password = e.target.password.value;
		const team = e.target.team.value;
		const name = e.target.name.value;
		formRef.current.reset();
		const info = `{"name": "${name}", "team": "${team}"}`;
		createUserWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				const user = userCredential.user;
				updateProfile(user, {
					displayName: info
				});
				const UID = user.uid;
				const teamDocRef = doc(db, 'teams', team);
				setDoc(teamDocRef, { isActive: false });
				const userDocRef = doc(db, 'teams', team, 'users', UID);
				setDoc(userDocRef, { isAdmin: true, name: name, sessions: [], email: email });

				signInWithEmailAndPassword(auth, email, password);
			})
			.catch((err) => {
				console.log(err.message);
			})
	}

	return (
		<div className='sign-in-card'>
			<img src={logo} width={180} alt='Uniqium Logo' />
			<form
				ref={formRef}
				onSubmit={(e) => handleSignUp(e)}
			>
				<div className='input-container'>
					<label>Business Email</label>
					<input name='email' placeholder='i.e. john@acme.com' autoComplete='off' />
				</div>
				<div className='input-container'>
					<label>Full Name</label>
					<input name='name' placeholder='John Doe' autoComplete='off' />
				</div>
				<div className='input-container'>
					<label>Company</label>
					<input name='team' placeholder='Acme Inc' autoComplete='off' />
				</div>
				<div className='input-container'>
					<label>Password</label>
					<input name='password' type='password' />
				</div>
				<div className='input-container'>
					<label>Confirm Password</label>
					<input name='confirm-password' type='password' />
				</div>
				<button className='btn-cta' type='submit'>Sign Up</button>
			</form>
		</div>
	);
}

export default SignUpForm;