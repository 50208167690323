import Tree from 'react-d3-tree';
import { useCenteredTree } from "./helpers";

import { db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';
import { functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';

import { useEffect, useState, useRef } from 'react';

import Timer from './Timer';
import DynamicTextArea from './DynamicTextArea';

import { Plus, Heart, Pencil, Lightbulb, HelpCircle, Info, Sparkle } from 'lucide-react';
import { Oval, Comment } from 'react-loader-spinner';
import LoadingIndicator from './LoadingIndicator';

function BrainstormingCanvas({ signedInUser, signedInUserTeam, selectedSession }) {
  const [translate, containerRef] = useCenteredTree();
  const [treeData, setTreeData] = useState();
  const [sessionInfo, setSessionInfo] = useState({});
  const [assigneeNames, setAssigneeNames] = useState([])
  const [nodeToEdit, setNodeToEdit] = useState(null);
  const [nodeToEditText, setNodeToEditText] = useState(null);
  const [newSeed, setNewSeed] = useState('');
  const [timerValue, setTimerValue] = useState(null);
  const [isTimerOn, setIsTimerOn] = useState(false);
  const [showSessionDetails, setShowSessionDetails] = useState(false);
  const [loadingNode, setLoadingNode] = useState(null);
  const [resetLoading, setResetLoading] = useState(false);

  const nodeToEditRef = useRef({ value: 0 });
  const ideaInputRef = useRef({ value: 0 });

  const createNewIdea = (nodeName, root, userId) => {
    return new Promise((resolve, reject) => {
      const callGpt4 = httpsCallable(functions, 'callGpt4');
      callGpt4({ text: nodeName, root: root, userId: userId })
        .then((response) => {
          const idea = response.data.completionText;
          logEvent(analytics, 'new_idea', { text: idea })
          resolve(idea);
        })
        .catch((err) => console.log(err));
    });
  }

  const checkCompliance = (newSeed) => {
    return new Promise((resolve, reject) => {
      const callModeration = httpsCallable(functions, 'callModeration');
      callModeration({ text: newSeed })
        .then((response) => {
          const flagged = response.data.result;
          resolve(flagged);
        })
        .catch((err) => console.log(err));
    });
  }

  const addNode = (root, nodeName, newIdea) => {
    if (root.name === nodeName) {
      root.children.push(newIdea);
    }
    for (const child of root.children) {
      addNode(child, nodeName, newIdea);
    }
  }

  const updateTreeData = (root) => {
    const docRef = doc(db, 'teams', signedInUserTeam, 'sessions', selectedSession, 'trees', signedInUser.uid);
    updateDoc(docRef, root);
    setTreeData(root);
  }

  const handleAddButtonClick = (node) => {
    if (isTimerOn) {
      const userId = signedInUser.uid;

      const nodeData = node.data;
      const nodeName = nodeData.name;
      setLoadingNode(nodeName);

      const root = { ...treeData };

      createNewIdea(nodeName, root, userId)
        .then((idea) => {
          const newIdea = { 'name': idea, 'children': [], attributes: { isFavorite: false } };
          addNode(root, nodeName, newIdea);
          updateTreeData(root);
          setLoadingNode(null);
        });
    } else if (timerValue === 0) {
      alert('This session is expired');
    } else {
      alert('Please start the timer to begin brainstorming');
    }
  }

  const checkIfRunning = (isRunning) => {
    setIsTimerOn(isRunning);
  }

  const handleEdit = (nodeClicked) => {
    if (nodeToEdit === null) {
      setNodeToEdit(nodeClicked);
      setNodeToEditText(nodeClicked);
    } else {
      setNodeToEdit(null);
      setNodeToEditText(null);
    }
  }

  const getNewNodeText = (e) => {
    e.preventDefault();
    setNodeToEditText(e.target.value);
  }

  const updateNode = (root, nodeClicked, editedIdea) => {
    if (root.name === nodeClicked) {
      root.name = editedIdea;
    }
    for (const child of root.children) {
      updateNode(child, nodeClicked, editedIdea);
    }
  }

  const handleUpdateButtonClick = (e, nodeClicked) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setLoadingNode(nodeClicked);
      const editedIdea = nodeToEditRef.current.value;
      checkCompliance(editedIdea)
        .then((flagged) => {
          if (flagged) {
            alert('Your input was flagged for violent and or sexual language.');
            nodeToEditRef.current.value = '';
            setLoadingNode(null);
            setNodeToEdit(null);
          } else if (editedIdea !== '') {
            const root = { ...treeData };
            updateNode(root, nodeClicked, editedIdea);
            updateTreeData(root);
            setLoadingNode(null);
            setNodeToEdit(null);
          } else {
            alert('Oops... You forgot to write something!');
          }
        });
    }
  }

  const handleSetSeedInput = (e) => {
    const userInput = e.target.value;
    setNewSeed(userInput);
  }

  const handleSetSeedEnter = (e) => {
    if (e.key === 'Enter') {
      console.log(ideaInputRef.current);
      setNewSeed(ideaInputRef.current.value);
      ideaInputRef.current.blur();
      handleResetTree();
    }
  }

  const handleResetTree = () => {
    setResetLoading(true);
    checkCompliance(newSeed)
      .then((flagged) => {
        if (flagged) {
          alert('Your input was flagged for violent and or sexual language.');
          setResetLoading(false);
          ideaInputRef.current.value = '';
          setNewSeed('');
        } else if (newSeed !== '') {
          const root = {
            name: newSeed,
            children: [],
            attributes: { isFavorite: false },
          }
          updateTreeData(root);
          ideaInputRef.current.value = '';
          setNewSeed('');
          setResetLoading(false);
        } else {
          alert('Oops... You forgot to write something!');
          setResetLoading(false);
        }
      })
  }

  const updateFavorite = (root, nodeClicked, bool) => {
    if (root.name === nodeClicked) {
      root.attributes.isFavorite = bool;
    }
    for (const child of root.children) {
      updateFavorite(child, nodeClicked, bool);
    }
  }

  const handleAddFavorite = async (nodeClicked) => {
    const docRef = doc(db, 'teams', signedInUserTeam, 'sessions', selectedSession);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    const favoritesList = data.favorites;
    favoritesList.push(nodeClicked);
    updateDoc(docRef, { favorites: favoritesList });
    const root = { ...treeData };
    updateFavorite(root, nodeClicked, true);
    updateTreeData(root);
  }

  const handleRemFavorite = async (nodeClicked) => {
    const docRef = doc(db, 'teams', signedInUserTeam, 'sessions', selectedSession);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    const favoritesList = data.favorites;
    const newFavoritesList = favoritesList.filter((fav) => {
      return (fav !== nodeClicked);
    });
    updateDoc(docRef, { favorites: newFavoritesList });
    const root = { ...treeData };
    updateFavorite(root, nodeClicked, false);
    updateTreeData(root)
  }

  const saveTime = (runningTime) => {
    const docRef = doc(db, 'teams', signedInUserTeam, 'sessions', selectedSession);
    const userId = signedInUser.uid;
    const timerValueObj = sessionInfo.timerValue;
    timerValueObj[userId] = runningTime;
    updateDoc(docRef, { timerValue: timerValueObj });
  }

  const renderFunction = (props) => {
    const nodeClicked = props.nodeDatum.name;
    const nodeClickedChildren = props.nodeDatum.children;
    const isFavorite = props.nodeDatum.attributes.isFavorite;
    return (
      <foreignObject
        className='node-container'
        width={320}
        y={-10}
      >
        <div className='node-controls'>
          <button
            className={nodeClicked === loadingNode ? 'btn-node btn-loading' : 'btn-node btn-cta'}
            onClick={nodeClicked === loadingNode ? () => { } : props.onNodeClick}
          >
            {nodeClicked === loadingNode ?
              <Oval
                height={16}
                width={16}
                color="#d35a04"
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#FA715F"
                strokeWidth={4}
                strokeWidthSecondary={4}
              />
              :
              <Plus size={16} />
            }
          </button>
          {nodeClicked !== treeData.name &&
            <>
              <button className='btn-node btn-regular' onClick={() => { handleEdit(nodeClicked) }}><Pencil size={16} /></button>
              {!isFavorite ?
                <button className='btn-node btn-regular' onClick={() => { handleAddFavorite(nodeClicked) }}><Heart size={16} /></button>
                :
                <button className='btn-node btn-favorite' onClick={() => { handleRemFavorite(nodeClicked) }}><Heart size={16} /></button>}
            </>
          }
        </div>
        <div className='card'>
          {nodeToEdit === nodeClicked
            ?
            <DynamicTextArea
              nodeToEditText={nodeToEditText}
              nodeToEditRef={nodeToEditRef}
              handleUpdateButtonClick={handleUpdateButtonClick}
              nodeClicked={nodeClicked}
              nodeClickedChildren={nodeClickedChildren}
              getNewNodeText={getNewNodeText}
            />
            :
            <p className='idea-text'>{props.nodeDatum.name}</p>
          }
        </div>
        {treeData.children.length === 0 &&
          <div className='instructions-card'>
            <div className='instruction-header'>
              <HelpCircle size={16} />
              How does this work?
            </div>
            <p>
              This is your seed idea. For the best results, provide as much context as possible.
            </p>
            <div style={{ height: 1, backgroundColor: '#607C8B' }}></div>
            <div className='instruction-header'>
              <Lightbulb size={16} />
              Examples
            </div>
            <ol>
              <li>Abstract logo for food journaling app that symbolizes harmony, balance, and strength</li>
              <li className='no-padding-bottom'>Video ad for self-driving electric car that emphasizes the car’s safety and comfort</li>
            </ol>
          </div>
        }
      </foreignObject>
    );
  }

  useEffect(() => {
    const getSessionData = async () => {
      const userId = signedInUser.uid;
      const docRef = doc(db, 'teams', signedInUserTeam, 'sessions', selectedSession);
      const docSnap = await getDoc(docRef);
      const data = docSnap.data();
      setSessionInfo(data);
      setTimerValue(data.timerValue[userId]);
      const assigneeIDs = data.assignees;
      const tmp = [];
      assigneeIDs.forEach(async (id) => {
        const docRef = doc(db, 'teams', signedInUserTeam, 'users', id);
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();
        const assigneeName = data.name;
        tmp.push(assigneeName);
      });
      setAssigneeNames(tmp);
    }
    getSessionData();

    const getTreeData = async () => {
      const docRef = doc(db, 'teams', signedInUserTeam, 'sessions', selectedSession, 'trees', signedInUser.uid);
      const docSnap = await getDoc(docRef);
      const data = docSnap.data();
      setTreeData(data);
    }
    setTimeout(() => { getTreeData() }, 1000);
    // Need to wait in case doc being created
  }, [signedInUserTeam, signedInUser, selectedSession]);

  return (
    <>
      <div className='controls-bar'>
        <div className='session-name-container'>
          <h2>{sessionInfo.sessionName}</h2>
          <div className='info-icon' onMouseEnter={() => setShowSessionDetails(true)} onMouseLeave={() => setShowSessionDetails(false)}>
            <Info size={24} />
            {showSessionDetails &&
              <div className='session-details-card'>
                <label>Project</label>
                <p className='session-details-text'>{sessionInfo.project}</p>
                <label>Objective</label>
                <p className='session-details-text'>{sessionInfo.objective}</p>
                <label>Instructions</label>
                <p className='session-details-text'>{sessionInfo.instructions}</p>
                <label>Assignees</label>
                <ul className='session-details-assignees'>
                  {assigneeNames.map((assignee) => {
                    return (
                      <li className='assignee-badge'>
                        {assignee}
                      </li>
                    );
                  })}
                </ul>
              </div>
            }
          </div>
        </div>
        {timerValue > 0 ?
          <div className='controls'>
            <div className='control-container'>
              <input
                className={ideaInputRef.current.value.length >= 100 ? 'seed-input too-long' : 'seed-input'}
                placeholder='What are you working on?'
                onInput={handleSetSeedInput}
                onKeyDown={handleSetSeedEnter}
                ref={ideaInputRef}
                maxLength={100}
              />
              {resetLoading &&
                <div style={{ display: 'flex', alignItems: 'center', paddingRight: 16, paddingTop: 2 }}>
                  <Comment
                    visible={true}
                    height="40"
                    width="40"
                    ariaLabel="comment-loading"
                    wrapperStyle={{}}
                    wrapperClass="comment-wrapper"
                    color="#607C8B"
                    backgroundColor='transparent'
                  />
                </div>}
              <button className='btn-set-seed btn-icon' onClick={resetLoading ? () => { } : handleResetTree}><Sparkle />Set Seed</button>
            </div>
            <Timer setIsTimerOn={setIsTimerOn} timerValue={timerValue} setTimerValue={setTimerValue} autoStart={false} checkIfRunning={checkIfRunning} saveTime={saveTime} />
          </div>
          : timerValue === 0 ?
            <p className='expired-label'>Expired</p>
            :
            <></>
        }
      </div>
      <div className='tree-container' ref={containerRef} id="treeWrapper">
        {treeData === undefined ? <LoadingIndicator /> :
          <Tree
            translate={translate}
            data={treeData}
            onNodeClick={(node) => { handleAddButtonClick(node) }}
            nodeSize={{ x: 500, y: 280 }}
            collapsible={false}
            renderCustomNodeElement={(props) => renderFunction(props)}
            hasInteractiveNodes={true}
          />
        }
      </div>
    </>
  );
}

export default BrainstormingCanvas;