import { useState } from 'react';

import { auth } from '../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';

import logo from '../assets/logo-color.png';
import { Menu, ChevronLeft, AtSign, LifeBuoy, Lock, X } from 'lucide-react';

function Header({ userData, handleSignOut, showTree, setShowTree, showSession, setShowSession }) {
  const [showMenu, setShowMenu] = useState(false);

  const handlePasswordReset = () => {
    const email = userData.email;
    sendPasswordResetEmail(auth, email)
      .then(() => {
        alert('A password reset email was sent.');
      })
      .catch((error) => {
        console.log(error)
      });
  }

  return (
    <div className='header'>
      {showMenu &&
        <div className='hamburger-menu'>
          <a className='hamburger-link-wrapper' href='mailto:contact@uniqium.com'><button className='btn-regular btn-icon btn-hamburger'><LifeBuoy />Get Help</button></a>
          <button className='btn-regular btn-icon btn-hamburger' onClick={() => handlePasswordReset()}><Lock />Reset Password</button>
          <a className='hamburger-link-wrapper' href='https://www.twitter.com/uniqium' target='_blank' rel='noreferrer'><button className='btn-regular btn-icon btn-hamburger'><AtSign />Follow Us</button></a>
        </div>
      }
      <div className='header-left'>
        {
          showTree ?
            <button className='btn-header btn-chevron' onClick={() => setShowTree(false)}><ChevronLeft /></button>
            : showSession ?
              <button className='btn-header btn-chevron' onClick={() => setShowSession(false)}><ChevronLeft /></button>
              :
              <button className='btn-header' onClick={() => setShowMenu(!showMenu)}>{showMenu ? <X /> : <Menu />}</button>
        }
      </div>
      <div className='header-center'>
        <img src={logo} width={160} alt='Uniqium Logo' />
      </div>
      <div className='header-right'>
        <div className='user-profile'>
          <h3>{userData.name}</h3>
          <button className='btn-regular' onClick={() => handleSignOut()}>Sign Out</button>
        </div>
      </div>
    </div>
  );
}

export default Header;