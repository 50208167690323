import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

import { useEffect, useState } from 'react';

function SessionReviewScreen({ selectedSession, signedInUserTeam, signedInUser }) {
  const [sessionInfo, setSessionInfo] = useState({ favorites: [] });
  const [assigneeList, setAssigneeList] = useState([]);

  useEffect(() => {
    const getSessionData = async () => {
      const docRef = doc(db, 'teams', signedInUserTeam, 'sessions', selectedSession,);
      const docSnap = await getDoc(docRef);
      const data = docSnap.data();
      setSessionInfo(data);

      const assignees = data.assignees;
      const tmp = [];
      assignees.forEach(async (user) => {
        const docRef = doc(db, 'teams', signedInUserTeam, 'users', user);
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();
        const userProfile = { name: data.name, UID: user };
        tmp.push(userProfile);
        setAssigneeList(tmp);
      });
    }
    getSessionData();
  }, [signedInUserTeam, signedInUser, selectedSession]);

  return (
    <div className='admin-dash-container'>
      <div className='dash-50-left'>
        <h2>{sessionInfo.sessionName}</h2>
        <div className='session-info'>
          <label>Project</label>
          <p className='session-details-text'>{sessionInfo.project}</p>
          <label>Objective</label>
          <p className='session-details-text'>{sessionInfo.objective}</p>
          <label>Instructions</label>
          <p className='session-details-text'>{sessionInfo.instructions}</p>
          <label>Assignees</label>
          <ul className='session-details-assignees'>
            {assigneeList.map((assignee) => {
              return (
                <li className='assignee-badge'>
                  {assignee.name}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className='dash-50'>
        <h2>Favorites</h2>
        <ul className='favorites-list'>
          {sessionInfo.favorites.map((idea) => {
            return (
              <li className='favorite' key={idea}>
                <p className='idea-text'>{idea}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default SessionReviewScreen;